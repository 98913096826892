import React, { useState } from 'react';
import { Tab } from '../components/Tab';
import HistoryImgOne from '../assets/img/history-1.svg';
import HistoryImgTwo from '../assets/img/history-2.png';
import HistoryImgThree from '../assets/img/history-3.png';
import HistoryImgFour from '../assets/img/history-4.png';
import HistoryMobileImgOne from '../assets/img/history-mobile-1.svg';
import HistoryMobileImgTwo from '../assets/img/history-mobile-2.png';
import HistoryMobileImgThree from '../assets/img/history-mobile-3.png';
import HistoryMobileImgFour from '../assets/img/history-mobile-4.png';
import GovernanceImgOne from '../assets/img/governance-1.svg';
import GovernanceImgTwo from '../assets/img/governance-2.png';
import GovernanceImgThree from '../assets/img/governance-3.svg';
import GovernanceImgFour from '../assets/img/governance-4.png';
import LeadershipImg from '../assets/img/leadership-img.png';
import { Section } from '../components/Section';
import { H1 } from '../components/H1';
import { ReactComponent as TickIcon } from '../assets/svg/icon-tick.svg';
import { Link } from 'react-router-dom';
import { LeadershipOfficers } from '../components/LeadershipOfficers';
import { LeadershipDirectors } from '../components/LeadershipDirectors';
import { LeadershipGovernors } from '../components/LeadershipGovernors';

const timelineTabs = [
  { name: 'Present' },
  { name: '2006 - 2001' },
  { name: '2000 - 1997' },
  { name: '1995 - 1987' },
];

const governanceTabs = [
  { name: 'Presidential Cabinet' },
  { name: 'Board of Directors' },
  { name: 'Regional Governance' },
  { name: 'Committee Chairs ' },
];

export const WhoAreWePage = () => {
  const [selectedTab, setSelectedTab] = useState(timelineTabs[0]);
  const [selectedTab2, setSelectedTab2] = useState(governanceTabs[0]);

  return (
    <>
      <Section className="items-center max-w-6xl text-center">
        <H1>About IAKL</H1>
        <p className="text-20 md:pt-7 pt-3 text-gray-500 pb-8 leading-relaxed whitespace-pre-line">
          {`The International Association of Korean Lawyers (IAKL), established in 1988, is a worldwide network
          for lawyers interested in Korea and Korean legal practice. Since its inauguration, IAKL has operated
          as a platform to foster professional relationships for over 3,500 lawyers from 23 countries.

          Currently, IAKL organizes annual conferences that provide valuable opportunity for everyone to meet
          and network with legal experts practicing in major law firms in Korea, in-house counsels, lawyers of
          Korean descent practicing in various geographical regions of the world, and international lawyers
          practicing in fields related to the Korean market.`}
        </p>

        <div className="border-b border-gray-200 w-80"></div>

        <h2 className="text-24 pt-8 pb-4">Our Mission</h2>
        <p className="text-gray-500 text-17 max-w-4xl mx-auto whitespace-pre-line">
          {`To bring together legal professionals of Korean descent or with interests in Korea throughout the 
          world to build professional relationships and to serve the public interest by promoting social justice.`}
        </p>

        <h2 className="text-24 pt-16 pb-4">Core Principles Of IAKL</h2>

        <div className="flex flex-col md:space-y-0 space-y-4 md:flex-row md:space-x-6 text-left">
          <div className="bg-gray-50 px-6 py-8 rounded-xl space-y-4 flex-1">
            <TickIcon />
            <h2 className="text-17 font-bold">Affinity and Diversity</h2>
            <p className="text-15 text-gray-600">
              Celebrate our heritage and the Korean experience. Acknowledge the
              diversity among lawyers of Korean heritage and respect the
              contributions our diversity makes to our community and profession.
            </p>
          </div>
          <div className="bg-gray-50 px-6 py-8 rounded-xl space-y-4 flex-1">
            <TickIcon />
            <h2 className="text-17 font-bold">Accomplishment and Guidance</h2>
            <p className="text-15 text-gray-600">
              Celebrate the individual and collective achievements of lawyers of
              Korean heritage around the world. Foster professional development
              and provide guidance for our younger generations to be involved in
              the legal profession.
            </p>
          </div>
          <div className="bg-gray-50 px-6 py-8 rounded-xl space-y-4 flex-1">
            <TickIcon />
            <h2 className="text-17 font-bold">
              Social Justice and Public Service
            </h2>
            <p className="text-15 text-gray-600">
              Promote social justice and take appropriate action to prevent
              injustices in situations where we can make a difference.
              Understand the sacrifices of others that have made possible the
              opportunities now available, and serve the public interest
              consistent with our international focus.
            </p>
          </div>
        </div>
      </Section>

      <div className="w-full bg-brand-3">
        <Section className="items-center max-w-5xl text-center">
          <H1>About IAKL</H1>

          <div className="pb-14 space-x-4 flex justify-center pt-8">
            {timelineTabs.map((tab) => (
              <Tab
                key={tab.name}
                selected={tab.name === selectedTab.name}
                onClick={() => setSelectedTab(tab)}
                name={tab.name}
              />
            ))}
          </div>

          <div className="w-full flex flex-col justify-center">
            {selectedTab.name === timelineTabs[0].name && (
              <div className="flex justify-center">
                <img src={HistoryImgOne} alt="" className="hidden md:block" />
                <img src={HistoryMobileImgOne} alt="" className="md:hidden" />
              </div>
            )}

            {selectedTab.name === timelineTabs[1].name && (
              <div className="flex justify-center">
                <img src={HistoryImgTwo} alt="" className="hidden md:block" />
                <img src={HistoryMobileImgTwo} alt="" className="md:hidden" />
              </div>
            )}

            {selectedTab.name === timelineTabs[2].name && (
              <div className="flex justify-center">
                <img src={HistoryImgThree} alt="" className="hidden md:block" />
                <img src={HistoryMobileImgThree} alt="" className="md:hidden" />
              </div>
            )}

            {selectedTab.name === timelineTabs[3].name && (
              <div className="flex justify-center">
                <img src={HistoryImgFour} alt="" className="hidden md:block" />
                <img src={HistoryMobileImgFour} alt="" className="md:hidden" />
              </div>
            )}
          </div>
        </Section>
      </div>

      <div className="items-center text-center">
        <div className="relative w-full h-full">
          <img
            src={LeadershipImg}
            alt=""
            className="md:h-52 w-full h-36 object-cover"
          />
          <H1 className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            Leadership
          </H1>
        </div>

        <Section className="w-full flex flex-col justify-center max-w-5xl md:space-y-48 space-y-28">
          <LeadershipOfficers />

          <LeadershipDirectors />

          <LeadershipGovernors />
        </Section>
      </div>
    </>
  );
};
