import React from 'react';

export const LeadershipOfficers = () => {
  return (
    <div className="flex md:flex-row flex-col items-start md:space-x-48 space-x-0 space-y-10 md:space-y-0 w-full">
      <p className="font-semibold md:text-36 text-30 md:w-32 w-full text-left">
        Officers
      </p>

      <div className="w-full space-y-20">
        {/* Korea */}
        <div className="flex flex-col items-start md:space-y-10 space-y-6 w-full">
          <p className="font-medium md:text-32 text-24 text-gray-600">Korea</p>

          <div className="md:space-y-4 space-y-2 w-full">
            <div className="flex items-center justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600">
                President and Board Chair
              </p>

              <a
                href="https://labpartners.co.kr/en/2018/05/peoples/3535/?ckattempt=1"
                target="_blank"
                className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 md:text-left text-right"
                rel="noreferrer"
              >
                Young-Hee Jo
              </a>
            </div>

            <div className="flex items-start justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600 w-72 text-left">
                Vice President
              </p>

              <div className="flex flex-col md:items-start items-end space-y-1 flex-shrink-0">
                <a
                  href="https://www.pilnet.org/profiles/pillkyu-hwang/"
                  target="_blank"
                  className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 text-left"
                  rel="noreferrer"
                >
                  Pillkyu Hwang
                </a>

                <a
                  href="https://www.pilnet.org/profiles/pillkyu-hwang/"
                  target="_blank"
                  className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 text-left"
                  rel="noreferrer"
                >
                  Sky Yang
                </a>

                <a
                  href="https://www.shinkim.com/eng/member/view/155"
                  target="_blank"
                  className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 text-left"
                  rel="noreferrer"
                >
                  Tak Kyun Hong
                </a>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600">
                Secretary
              </p>

              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 md:text-left text-right">
                Kyoungsun Jung
              </p>
            </div>
          </div>
        </div>

        {/* Overseas */}
        <div className="flex flex-col items-start md:space-y-10 space-y-6 w-full">
          <p className="font-medium md:text-32 text-24 text-gray-600">
            Overseas
          </p>

          <div className="md:space-y-4 space-y-2 w-full">
            <div className="flex items-center justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600">
                President
              </p>

              <a
                href="https://www.weirfoulds.com/people/philip-cho"
                target="_blank"
                className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 md:text-left text-right"
                rel="noreferrer"
              >
                Philip Cho (Interim)
              </a>
            </div>

            <div className="flex items-center justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600">
                Vice President
              </p>

              <a
                href="https://councilka.org/cka_member/wade-pyun/"
                target="_blank"
                className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 md:text-left text-right"
                rel="noreferrer"
              >
                Wade Pyun
              </a>
            </div>

            <div className="flex items-center justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600">
                Treasurer
              </p>

              <a
                href="https://linkedin.com/in/cristina-hwang"
                target="_blank"
                className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 md:text-left text-right"
                rel="noreferrer"
              >
                Cristina Hwang
              </a>
            </div>

            <div className="flex items-center justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600">
                Secretary
              </p>

              <a
                href="https://www.linkedin.com/in/mary-jane-yoon-90845732"
                target="_blank"
                className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 md:text-left text-right"
                rel="noreferrer"
              >
                Mary Jane Yoon
              </a>
            </div>

            <div className="flex items-center justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600">
                Board Chair
              </p>

              <a
                href="https://www.sgrlaw.com/attorneys/rho-peter/ter-rho/#bio"
                target="_blank"
                className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 md:text-left text-right"
                rel="noreferrer"
              >
                Peter Rho
              </a>
            </div>

            <div className="flex items-center justify-between">
              <p className="font-medium md:text-20 text-17 text-gray-600">
                Marketing & Technology Officer
              </p>

              <a
                href="https://mcmillan.ca/people/hans-chang/"
                target="_blank"
                className="md:text-17 text-15 text-gray-600 underline flex-shrink-0 md:w-40 md:text-left text-right"
                rel="noreferrer"
              >
                Hans Chang
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
